
a {
    text-decoration: none;
}
.proj-1:hover {
    color: #dcdcdc;
}
.proj-1:hover {
    color: rgba(147, 152, 239, 0.608);
}
.logo-image {
max-width:100%;
max-height:100%;
border-radius: 220px;
}
.Galerie-flex {
min-height: 100vh;
display: flex;
text-align: center;
}
.project-one {
padding-top: 10%;
width: 50%;
text-align: center;
background-color: #dcdcdc;
position: relative;
}
.Project-two {
padding-top: 10%;
width: 50%;
background-color: rgba(147, 152, 239, 0.608);
text-align: center;
}
.project-one-cercle {
/* background-image: url("cspdd.jpg"); */
width: 16em;
height: 16em;
border-radius: 50%;
background-color: rgb(27, 22, 23);
position: absolute;
top: 50%;
right: -8em;
transform: translateY(-50%);
}
.project-two-cercle{

}
.Galerie-h1 {
    color: blue;
    text-align: center;
    top: 50px;
    margin: 15px;
}

.special:hover {
   background-color: white;
}
.dropdown {
    padding-top: 10px;
    padding-right: 20px;
}
ul {
    list-style: none;
        
}
    ul li {
        display: inline-block;
        position: relative;
    }
    ul li a {
        display: block;
        text-decoration: none;
        color: #fff;
        text-align: center;
    }
    ul li ul.dropdown li {
        display: block;
    }
    ul li ul.dropdown {
        position:absolute ;
        z-index: 999;
        display:none;
    }
    ul li ul.dropdown a {
        width: 100px;
        transform: translateX(-17px);
    }
    ul li:hover ul.dropdown {
        display: block;
    }
    